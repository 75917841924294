import React, { FC, useEffect, useState } from "react";
import TextField from "../form/TextField";
import Button from "../form/Button";
import { selectOption } from "../../types/users";
import { ToggleBlock } from "../form/ToggleBlock";
import TransformObjArray from "../../mixins/transform-obj-array";
import RoleUsers from "../../mixins/role";
import DatePickerTimeField from "../form/DatePickerTimeField";
import DateOperations from "../../mixins/date-operation";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import FilterSelectByCity from "../form/FilterSelectByCity";
import TextArea from "../form/TextArea";
import { $changeIsQr } from "../../api/requests/center";
import { failureNotify, successNotify } from "../../notifications";

interface CardClubFormProps {
    onSave: (item: any) => void
    onDelete: () => void
    data: any
    isEdit: boolean
    isSave: boolean
}

const CardClubForm: FC<CardClubFormProps> = (
    {
        data,
        onSave,
        onDelete,
        isEdit,
        isSave
    }
) => {
    const transformObjArray = new TransformObjArray();
    const dateOperations = new DateOperations();

    const roleUsers = new RoleUsers();
    const isDeleteByRole = roleUsers.getModelRoleByType('center', 'delete');

    const userData = useSelector(GetCurrentUserData);
    const checkStatusUser = (userData?.role === 1) ? true : (userData?.status === 2);

    const [ postList, setPostList ] = useState<selectOption[]>([
        {
            label: 'Владелец',
            value: 'OWNER'
        },
        {
            label: 'Директор',
            value: 'DIRECTOR'
        },
        {
            label: 'Системный администратор',
            value: 'SYSTEM_ADMINISTRATOR'
        },
        {
            label: 'Администратор Зала',
            value: 'HALL_ADMINISTRATOR'
        }
    ]);

    const [ formList, setFormList ] = useState([
        {
            key: 'name',
            label: 'Название центра',
            value: data?.name || ''
        },
        {
            key: 'login',
            label: 'Логин от Шелла',
            value: data?.login || '',
            isHide: !isEdit
        },
        {
            key: 'password',
            label: 'Пароль от Шелла',
            value: data?.password || '',
            isHide: !isEdit
        },
        {
            key: 'cityId',
            label: 'Город',
            value: data?.city || '',
            list: [],
            select: true
        },
        {
            key: 'street',
            label: 'Улица',
            value: data?.street || '',
        },
        {
            key: 'house',
            label: 'Дом',
            value: data?.house || '',
        },
        // {
        //     key: 'position',
        //     label: 'Должность',
        //     value: postList.find(i => i.label === data?.position) || null,
        //     list: postList,
        //     select: true
        // },
        {
            key: 'employee',
            label: 'Имя сотрудника',
            value: data?.employee || '',
        },
        {
            type: 'number',
            key: 'timezone',
            label: 'Часовой пояс',
            value: data?.timezone || '',
        },
        {
            key: 'description',
            label: 'Описание',
            value: data?.description || '',
            textarea: true
        },
        {
            title: 'Настройки робокассы',
			isHide: !isEdit
        },
		{
			key: 'robokassa.id',
			label: 'Логин робокассы',
			value: '',
			isHide: !isEdit
		},
		{
			key: 'robokassa.password1',
			label: 'Первый пароль (тестовый)',
			value: '',
			isHide: !isEdit
		},
		{
			key: 'robokassa.password2',
			label: 'Второй пароль (тестовый)',
			value: '',
			isHide: !isEdit
		},
		{
			key: 'robokassa.passwordProd1',
			label: 'Первый пароль',
			value: '',
			isHide: !isEdit
		},
		{
			key: 'robokassa.passwordProd2',
			label: 'Второй пароль',
			value: '',
			isHide: !isEdit
		},
		{
			key: 'robokassa.isTest',
			label: 'Тестовый режим',
			value: '',
			checked: true,
			className: '',
			isHide: !isEdit
		},
        {
            title: 'Время работы',
        },
        {
            type: 'number',
            key: 'start',
            label: 'от',
            value: data?.start || 36000,
            className: 'w-50',
            date: true,
        },
        {
            type: 'number',
            key: 'end',
            label: 'до',
            value: data?.end || 79200,
            className: 'w-50',
            date: true,
        },
        {
            key: 'isOwner',
            label: 'Владелец центра',
            value: data?.isOwner || '',
            checked: true,
            className: 'mt-3',
            title: '',
            type: '',
            date: false
        },
        {
            key: 'isActive',
            label: 'Центр активен',
            value: data?.isActive || '',
            checked: true,
            className: '',
        },
        {
            key: 'qr_available',
            label: 'Обязательность СКУД',
            value: data?.qr_available || '',
            checked: true,
            className: '',
        },
    ]);

    useEffect(() => init(), [ data ])
    useEffect(() => {
        isSave && onClickSave();
    }, [ isSave ])

    function init() {
        if (!data) return;

        const listUpdate = [ ...formList ].map((i: any) => {
            if (data && Object.keys(data).includes(i.key)) {
                i.value = data[i.key];
            }

            if (i.key === 'cityId') {
                i.value = {
                    label: data?.city,
                    value: data?.cityId
                }
            }

            return i;
        });

        setFormList(listUpdate);
    }

    function onClickSave() {
        const obj = transformObjArray.getRequestObj(formList)

        const formFieldObject = {
            ...obj,
            timezone: +obj.timezone
        }

        onSave(formFieldObject)
    }

    function onClickDelete() {
        onDelete();
    }

    function onChangeListItem(value: any, key: string) {
        if (key === 'qr_available') {
            $changeIsQr({ [key]: value, centerId: data.id } ).then((res) => {
                res === 'Успешно обновлено' ? successNotify(res) : failureNotify(res)
            })
        }

        const list = [ ...formList ].map(i => {
            if (i.key === key) return {
                ...i,
                value
            }

            return i
        })

        setFormList(list)
    }

    return (
        <form>
            { formList.map((
                {
                    key,
                    select,
                    checked,
                    label,
                    value,
                    // list,
                    isHide,
                    className,
                    title,
                    type,
                    date,
                    textarea
                }, idx
            ) => {
                if (isHide) return null;

                if (select) return (
                    <FilterSelectByCity
                        key={ `card-club-select-item-${ idx }` }
                        placeholder='Фильтр по городу'
                        selectItem={ value }
                        setSelectItem={ (val) => key && onChangeListItem(val, key)  }
                    />
                )

                if (title) return (
                    <div className='title'
                         key={ `card-club-title-item-${ idx }` }
                    >
                        <p>{ title }</p>
                    </div>
                )

                if (checked) return (
                    <ToggleBlock
                        key={ `card-club-toggle-item-${ idx }` }
                        desc={ label }
                        isChecked={ value }
                        className={ className }
                        handleChange={ () => onChangeListItem(!value, key) }
                    />
                )

                if (date) return (
                    <DatePickerTimeField
                        key={ `card-club-date-item-${ idx }` }
                        className={ className }
                        startDate={new Date(`2023-10-08T${dateOperations.getTimeFromSeconds(value)}:00`)}
                        onChangeDate={ (date) =>
                            onChangeListItem(
                                dateOperations.getTimeInSeconds(dateOperations.getFormattedTime(date)),
                                key
                            ) }
                    />
                )

                if (textarea) return (
                    <TextArea
                        key={ `card-club-textarea-item-${ idx }` }
                        placeholder={ label }
                        value={ value }
                        onChangeValue={ (val) => key && onChangeListItem(val, key) }
                        rows={4}
                    />
                )

                return (
                    <TextField
                        type={ type }
                        key={ `card-club-text-item-${ idx }` }
                        label={ label }
                        value={ value }
                        className={ className }
                        onChangeValue={ (val) => key && onChangeListItem(val, key) }
                    />
                )
            }) }

            <div className="club__add__content-btn">
                { (isEdit && isDeleteByRole && checkStatusUser) && (
                    <Button
                        text={ 'Удалить' }
                        onClick={ onClickDelete }
                        className={ 'btn btn-danger w-100 justify-content-center' }
                        disabled={data?.isMain || (userData?.staffCenter?.id === data?.id)}
                    />
                ) }

                <Button
                    text={ 'Сохранить' }
                    onClick={ onClickSave }
                    className={ 'btn btn-primary w-100 justify-content-center' }
                />
            </div>
        </form>
    )
}

export default CardClubForm;
